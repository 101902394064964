<template>
  <div class="warp">
    <common-header></common-header>
    <div class="selection-box">
      <div class="item" @click="openPicker('0')">
        <span>{{startTime}}</span>
        <i class="icon-black-arrow"></i>
      </div>
      <div class="item" @click="openPicker('1')">
        <span>{{endTime}}</span>
        <i class="icon-black-arrow"></i>
      </div>
    </div>
    <table class="table" v-if="lists.length > 0"
      v-infinite-scroll="loadMore"
      infinite-scroll-disabled="loading"
      infinite-scroll-immediate-check="false"
      infinite-scroll-distance="10">
      <tr>
        <th class="name">商品</th>
        <th>押金</th>
        <th>总价值</th>
        <th>订单</th>
        <th>状态</th>
      </tr>
      <tr v-for="(item, index) in lists" :key="index"> 
        <td>{{item.name}}</td>
        <td>{{item.deposit}}</td>
        <td>{{item.totalAmount}}</td>
        <td>{{item.orderCode}}</td>
        <td>{{item.backDeposit == 0 ? '未退' : '已退'}}</td>
      </tr>
    </table>

    <no-data v-if="nodata" style="margin-top: 1rem"></no-data>
    
    <!-- <mt-popup class="w100" v-model="timeStartType" position="bottom"> -->
      <!-- <div class="select-popup-btn fix">
        <span class="btn fl" @click="timeStartType = false">取消</span>
        <span class="btn fr" @click="modifyCommodity">确定</span>
      </div> -->
      <mt-datetime-picker
        ref="picker"
        type="date"
        year-format="{value} 年"
        month-format="{value} 月"
        date-format="{value} 日"
        :endDate="endDate"
         @confirm="handleConfirm"
        v-model="timeStartValue">
      </mt-datetime-picker>
    <!-- </mt-popup> -->
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { DateFormat } from '@/utils/common';
export default {
  name: "deposit",
  data() {
    return {
      nomore: false,
      nodata: false,
      startTime: (DateFormat(new Date())).substring(0,10),
      endTime: (DateFormat(new Date())).substring(0,10),
      endDate: new Date(),
      timeStartType: true,
      timeStartValue: new Date(),
      timeIndex: '',
      lists: [],
      pages: 1,
      loading: false,
      pageNum: 1,
    }
  },
  mounted() {
    this.init({
      startDate: this.startTime,
      endDate: this.endTime,
      pageNum: 1,
    });
  },
  methods: {
    // 加载更多
    loadMore() {
      this.loading = true;
      let pageNum = this.pageNum + 1;
      if (pageNum > this.pages) {
        this.nomore = true;
      } else {
        this.init({
          startDate: this.startTime,
          endDate: this.endTime,
          pageNum: pageNum,
          add: 'add',
        });
      }
    },
    init(obj) {
      this.getDepositList({
        endDate: obj.endDate,
        pageNum: obj.pageNum,
        startDate: obj.startDate,
      }).then((res)=> {
        if (obj.add) {
          this.lists = this.lists.concat(res.data.list);
        } else {
          this.lists = res.data.list;
        }
        this.nodata = this.lists.length > 0 ? false : true;
        this.pages = res.data.pages;
        this.pageNum = res.data.pageNum;
      })
    },
    openPicker(i) {
      this.timeIndex = i;
      this.$refs.picker.open();
    },
    handleConfirm(e) {
      if (this.timeIndex == 0) {
        this.startTime = (DateFormat(e)).substring(0,10);
        this.init({
          startDate: this.startTime,
          endDate: this.endTime,
          pageNum: 1,
        });
      } else {
        this.endTime = (DateFormat(e)).substring(0,10);
        this.init({
          startDate: this.startTime,
          endDate: this.endTime,
          pageNum: 1,
        });
      }
    }, 
    ...mapActions('account', [
      'getDepositList',
    ])
  },
}
</script>
<style lang='less' scoped>
.table{
  width: 100%;
  margin-top: .4rem;
  text-align: center;
  .name{
    width: 2rem;
  }
  th{
    font-size: .3rem;
  }
  td{
    font-size: .28rem;
  }
  td,th{
    border: 1px solid #f5f5f5;
    padding: .2rem 0.1rem;
  }
}
.selection-box{
  padding-top: .4rem;
  display: flex;
  .item{
    border: 1px solid #f5f5f5;
    padding: .2rem;
    flex: 1;
    margin-right: .2rem;
    border-radius: .15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .28rem;
    &:last-child{
      margin-right: 0;
    }
  }
}
</style>